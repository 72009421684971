import { API_BASE } from '@/constants';
import axios from 'axios';

// initial state
const state = {
  dailyMenus: [],
  dailyMenu: {},
  loading: false,
  error: false,
};

// getters
const getters = {
  dailyMenu(state) {
    return state.dailyMenu;
  },
  dailyMenus(state) {
    return state.dailyMenus;
  },
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
};

// actions
const actions = {
  loadDailyMenu(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('error', false);
      context.commit('loading', true);

      const dailyMenu = payload.dailyMenu;
      axios
        .get(
          API_BASE +
            'restaurants/' +
            payload.restaurant +
            '/daily-menus/' +
            dailyMenu
        )
        .then(response => {
          context.commit('setDailyMenu', response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  load(context, payload) {
    // Return a promise
    return new Promise((resolve, reject) => {
      // Clean any errors
      context.commit('error', false);
      context.commit('loading', true);

      axios
        .get(API_BASE + 'restaurants/' + payload.restaurant + '/daily-menus')
        .then(response => {
          context.commit('set', response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  clear(context) {
    return new Promise(resolve => {
      context.commit('clear');
      resolve();
    });
  },

  create(context, dailyMenu) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);

      // For this to work we need a form data object
      const formData = new FormData();
      formData.append('restaurant_uuid', dailyMenu.restaurant_uuid);

      let locale;
      for (locale in dailyMenu.name) {
        if (dailyMenu.name[locale] !== null)
          formData.append('name[' + locale + ']', dailyMenu.name[locale]);
      }

      for (locale in dailyMenu.description) {
        if (dailyMenu.description[locale] !== null)
          formData.append(
            'description[' + locale + ']',
            dailyMenu.description[locale]
          );
      }

      for (locale in dailyMenu.notes) {
        if (dailyMenu.notes[locale] !== null)
          formData.append('notes[' + locale + ']', dailyMenu.notes[locale]);
      }

      formData.append('price', dailyMenu.price);
      formData.append('active', dailyMenu.active ? 1 : 0);

      if (dailyMenu.image) {
        formData.append('image', dailyMenu.image);
      }

      const endpoint = `${API_BASE}restaurants/${dailyMenu.restaurant_uuid}/daily-menus`;
      axios
        .post(endpoint, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  update(context, dailyMenu) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);

      // For this to work we need a form data object
      const formData = new FormData();
      formData.append('restaurant_uuid', dailyMenu.restaurant_uuid);

      let locale;
      for (locale in dailyMenu.name) {
        if (dailyMenu.name[locale] !== null)
          formData.append('name[' + locale + ']', dailyMenu.name[locale]);
      }

      for (locale in dailyMenu.description) {
        if (dailyMenu.description[locale] !== null)
          formData.append(
            'description[' + locale + ']',
            dailyMenu.description[locale]
          );
      }

      for (locale in dailyMenu.notes) {
        if (dailyMenu.notes[locale] !== null)
          formData.append('notes[' + locale + ']', dailyMenu.notes[locale]);
      }

      formData.append('price', dailyMenu.price);
      formData.append('active', dailyMenu.active ? 1 : 0);

      if (dailyMenu.image) {
        formData.append('image', dailyMenu.image);
      }

      const endpoint = `${API_BASE}restaurants/${dailyMenu.restaurant_uuid}/daily-menus/${dailyMenu.id}`;
      axios
        .post(endpoint, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  remove(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);

      const endpoint = `${API_BASE}restaurants/${payload.restaurant}/daily-menus/${payload.dailyMenu}`;

      axios
        .delete(endpoint, {
          data: { substituteDailyMenu: payload.substituteDailyMenu },
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  reorder(context, payload) {
    return new Promise((resolve, reject) => {
      // Just in case the reordering fails.
      const currentDailyMenus = context.state.dailyMenus;

      // Set now the reordered daily menus
      context.commit('set', payload.dailyMenus);
      context.commit('error', false);
      context.commit('loading', true);

      const orderedDailyMenus = payload.dailyMenus.map((dailyMenu, index) => ({
        id: dailyMenu.id,
        display_priority: index + 1,
      }));

      const endpoint =
        API_BASE + 'restaurants/' + payload.restaurant + '/reorder/daily-menus';

      axios
        .post(endpoint, { dailyMenus: orderedDailyMenus })
        .then(response => {
          context.commit('set', response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          // We have and error, lets just stick to the actual ordering.
          context.commit('set', currentDailyMenus);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },
};

// mutations
const mutations = {
  setDailyMenu(state, data) {
    state.dailyMenu = data;
  },
  set(state, data) {
    state.dailyMenus = data;
  },
  clear(state) {
    state.dailyMenus = [];
  },
  error(state, value) {
    state.error = value;
  },
  loading(state, value) {
    state.loading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
