import { API_BASE } from '../../constants';
import axios from 'axios';

// initial state
const state = {
  category: {},
  categories: [],
  loading: false,
  error: false,
};

// getters
const getters = {
  categories(state) {
    return state.categories;
  },
  category(state) {
    return state.category;
  },
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
};

// actions
const actions = {
  loadCategory(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('error', false);
      context.commit('loading', true);

      const category = payload.category;
      axios
        .get(
          API_BASE +
            'restaurants/' +
            payload.restaurant +
            '/categories/' +
            category
        )
        .then(response => {
          context.commit('setCategory', response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  load(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('error', false);
      context.commit('loading', true);

      axios
        .get(API_BASE + 'restaurants/' + payload.restaurant + '/categories')
        .then(response => {
          context.commit('set', response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  clear(context) {
    return new Promise(resolve => {
      context.commit('clear');
      resolve();
    });
  },

  create(context, category) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);

      // For this to work we need a form data object
      const formData = new FormData();
      formData.append('restaurant_uuid', category.restaurant_uuid);

      let locale;
      for (locale in category.name) {
        if (category.name[locale])
          formData.append('name[' + locale + ']', category.name[locale]);
      }

      for (locale in category.description) {
        if (category.description[locale])
          formData.append(
            'description[' + locale + ']',
            category.description[locale]
          );
      }

      formData.append('active', category.active ? 1 : 0);

      if (category.image) {
        formData.append('image', category.image);
      }

      axios
        .post(
          API_BASE + 'restaurants/' + category.restaurant_uuid + '/categories',
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  update(context, category) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);

      // For this to work we need a form data object
      const formData = new FormData();
      formData.append('restaurant_uuid', category.restaurant_uuid);

      let locale;
      for (locale in category.name) {
        if (category.name[locale] !== null)
          formData.append('name[' + locale + ']', category.name[locale]);
      }

      for (locale in category.description) {
        if (category.description[locale] !== null)
          formData.append(
            'description[' + locale + ']',
            category.description[locale]
          );
      }

      formData.append('active', category.active ? 1 : 0);

      if (category.image) {
        formData.append('image', category.image);
      }

      axios
        .post(
          API_BASE +
            'restaurants/' +
            category.restaurant_uuid +
            '/categories/' +
            category.id,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  remove(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      axios
        .delete(
          API_BASE +
            'restaurants/' +
            payload.restaurant +
            '/categories/' +
            payload.category,
          { data: { substituteCategory: payload.substituteCategory } }
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  reorder(context, payload) {
    return new Promise((resolve, reject) => {
      // Just in case the reordering fails.
      const currentCategories = context.state.categories;

      // Set now the reordered categories
      context.commit('set', payload.categories);
      context.commit('error', false);
      context.commit('loading', true);

      const orderedCategories = payload.categories.map((category, index) => ({
        id: category.id,
        display_priority: index + 1,
      }));

      const endpoint =
        API_BASE + 'restaurants/' + payload.restaurant + '/reorder/categories';

      axios
        .post(endpoint, { categories: orderedCategories })
        .then(response => {
          context.commit('set', response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          // We have and error, lets just stick to the actual ordering.
          context.commit('set', currentCategories);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },
};

// mutations
const mutations = {
  setCategory(state, data) {
    state.category = data;
  },
  set(state, data) {
    state.categories = data;
  },
  clear(state) {
    state.categories = [];
  },
  error(state, value) {
    state.error = value;
  },
  loading(state, value) {
    state.loading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
