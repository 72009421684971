import { API_BASE } from '@/constants';
import axios from 'axios';

const state = {
  plans: [],
  subscription: {},
  completePaymentUrl: '',

  error: false,
  loading: false,
};

const getters = {
  plans(state) {
    return state.plans;
  },
  subscription(state) {
    return state.subscription;
  },
  completePaymentUrl(state) {
    return state.completePaymentUrl;
  },
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
};

const actions = {
  load(context, restaurant) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      context.commit('error', false);

      axios
        .get(API_BASE + 'restaurants/' + restaurant + '/billing/plans')
        .then(response => {
          context.commit('setData', response.data);
          resolve(response.data);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  createPlan(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      context.commit('error', false);

      const uri = `${API_BASE}restaurants/${payload.restaurant}/billing/plans`;
      axios
        .post(uri, payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  subscribeTo(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      context.commit('error', false);

      const endpoint = `${API_BASE}restaurants/${payload.restaurant}/billing/subscriptions/${payload.plan.id}`;
      axios
        .post(endpoint)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  destroy(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      context.commit('error', false);

      const attributes = {
        payment_method: payload.payment_method,
      };

      const endpoint = `${API_BASE}restaurants/${payload.restaurant}/billing/payment-methods`;
      axios
        .delete(endpoint, { data: attributes })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  updateDefault(context, payload) {
    // restaurants/{restaurant}/billing/default-payment-method
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      context.commit('error', false);

      const attributes = {
        payment_method: payload.payment_method,
      };

      const endpoint = `${API_BASE}restaurants/${payload.restaurant}/billing/default-payment-method`;
      axios
        .post(endpoint, attributes)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },
};

const mutations = {
  error(state, value) {
    state.error = value;
  },
  loading(state, value) {
    state.loading = value;
  },

  setData(state, data) {
    state.plans = data.plans;
    state.subscription = data.subscription;
    state.completePaymentUrl = data.completePaymentUrl;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
