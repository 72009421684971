export default {
  login: {
    title: 'Formulario de acceso',
    email: 'Correo electrónico',
    password: 'Contraseña',

    reset: 'Olvidé mi contraseña',
    login: 'Iniciar sesión',

    // Validations
    v: {
      email: {
        email: 'El correo electrónico no es válido.',
        required: 'El correo electrónico es obligatorio.',
      },

      password: {
        required: 'La contraseña es obligatoria.',
      },
    },
  },

  /**
   * Navigation
   */
  navigation: {
    clients: 'Autorizaciones',
    orders: 'Pedidos',
    history: 'Histórico (pedidos)',
    categories: 'Categorías / Productos',
    dailyMenus: 'Menús del día',
    allergens: 'Alérgenos',
    receipts: 'Recibos',
    employees: 'Empleados',
    configuration: 'Configuración',
    contactInformation: 'Datos de contacto',
    tables: 'Mesas',
    feedback: 'Sugerencias',
    paymentMethod: 'Métodos de pago',
    subscriptions: 'Subscripciones',
    invoices: 'Facturas',
  },

  /**
   * Orders
   */
  orders: {
    title: 'Pedidos', // Menús del día
    noOrders: 'Actualmente no hay ningún pedido pendiente.',
  },

  /**
   * History
   */
  history: {
    title: 'Histórico (pedidos)', // Menús del día
    noOrders: 'Actualmente no hay ningún pedido pendiente.',

    typeList: {
      order: 'Pedido',
      bill: 'La cuenta',
      waiter: 'Llamada al camarero',
    },
  },

  /**
   * Daily menus
   */
  dailyMenus: {
    title: 'Menús del día',
    noDailyMenus:
      'No hay ningún menú del día actualmente en el sistema. Por favor, utilice el boton "añadir" que se encuentra abajo a la derecha para crear uno.',

    breadcrumb: {
      edit: '{name}',
      create: 'Nuevo menú del día',
    },

    dailyMenu: {
      edit: 'Editar',
      delete: 'Eliminar',
      noDescription: 'Este menú del día no contiene una descripción.',
    },
  },

  /**
   * Categories
   */
  categories: {
    title: 'Categorías',

    breadcrumb: {
      create: 'Nueva categoría',
      edit: '{name}',
    },

    noCategories:
      'No hay ninguna categoría actualmente en el sistema. Por favor, utilice el boton "añadir" que se encuentra abajo a la derecha para crear una categoría.',

    category: {
      edit: 'Editar',
      delete: 'Eliminar',
      noDescription: 'Esta categoría no contiene una descripción.',
    },
  },

  /**
   * Products
   */
  products: {
    breadcrumb: {
      create: 'Nuevo producto',
      edit: '{name}',
    },

    title: 'Productos',

    noProducts:
      'No hay ningún producto actualmente en el sistema. Por favor, utilice el boton "añadir" que se encuentra abajo a la derecha para crear uno.',

    product: {
      edit: 'Editar',
      delete: 'Eliminar',
      noDescription: 'Este producto no contiene una descripción.',
    },
  },

  /**
   * Allergens
   */
  allergens: {
    breadcrumb: {
      create: 'Nuevo alérgeno',
      edit: '{name}',
    },

    title: 'Alérgenos',

    noAllergens:
      'No hay ningún alérgeno actualmente en el sistema. Por favor, utilice el boton "añadir" que se encuentra abajo a la derecha para crear uno.',

    allergen: {
      edit: 'Editar',
      delete: 'Eliminar',
      noDescription: 'Este alérgeno no contiene una descripción.',
    },
  },

  /**
   * Clients
   */
  clients: {
    title: 'Clientes',

    noClients:
      'No hay ningún cliente en el sistema para los criterios de búsqueda.',
  },

  /**
   * Client
   */
  client: {
    notAuthorized: 'No autorizado',
  },

  /**
   * Configuration
   */
  configuration: {
    title: 'Configuración',
  },

  /**
   * Employees
   */
  employees: {
    title: 'Empleados',
    breadcrumb: {
      create: 'Nuevo empleado',
      edit: '{name}',
    },
  },

  /**
   * Payment Methods
   */
  paymentMethods: {
    title: 'Métodos de pago',
  },

  /**
   * Subscriptions
   */
  subscriptions: {
    title: 'Planes y Subscripciones',
  },

  validations: {
    required: 'El campo es obligatorio.',
    required_i18n: 'El campo es obligatorio en todos los idiomas.',
    numeric: 'El campo tiene que ser un número.',
    length: 'El campo puede contener como máximo {0} caracteres.',
    fileSize: 'El tamaño de la imagen no puede superar {0}kb.',
    email: 'Correo electrónico inválido.',
    dni: 'El formato del documento de identidad es inválido',
    equalsTo: 'Las dos contraseñas no coinciden.',
  },
};
