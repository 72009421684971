import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { Ripple } from 'vuetify/lib/directives';
import es from 'vuetify/lib/locale/es';
import en from 'vuetify/lib/locale/en';

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
});

export default new Vuetify({
  lang: {
    locales: { es, en },
    current: 'es',
  },
});
