import { API_BASE } from '@/constants';
import axios from 'axios';

// initial state
const state = {
  allergens: [],
  allergen: {},
  loading: false,
  error: false,
};

// getters
const getters = {
  allergens(state) {
    return state.allergens;
  },
  allergen(state) {
    return state.allergen;
  },
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
};

// actions
const actions = {
  loadAllergen(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('error', false);
      context.commit('loading', true);

      const allergen = payload.allergen;
      axios
        .get(
          API_BASE +
            'restaurants/' +
            payload.restaurant +
            '/allergens/' +
            allergen
        )
        .then(response => {
          context.commit('setAllergen', response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  load(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('error', false);
      context.commit('loading', true);

      axios
        .get(API_BASE + 'restaurants/' + payload.restaurant + '/allergens')
        .then(response => {
          context.commit('set', response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  create(context, allergen) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);

      // For this to work we need a form data object
      const formData = new FormData();

      formData.append('restaurant_uuid', allergen.restaurant_uuid);

      let locale;
      for (locale in allergen.name) {
        if (allergen.name[locale])
          formData.append('name[' + locale + ']', allergen.name[locale]);
      }

      for (locale in allergen.description) {
        if (allergen.description[locale])
          formData.append(
            'description[' + locale + ']',
            allergen.description[locale]
          );
      }

      formData.append('active', allergen.active ? 1 : 0);
      formData.append('image', allergen.image);

      axios
        .post(
          API_BASE + 'restaurants/' + allergen.restaurant_uuid + '/allergens',
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  remove(context, allergen) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      axios
        .delete(
          API_BASE +
            'restaurants/' +
            allergen.restaurant_uuid +
            '/allergens/' +
            allergen.id
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  update(context, allergen) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);

      // For this to work we need a form data object
      const formData = new FormData();

      formData.append('id', allergen.id);
      formData.append('restaurant_uuid', allergen.restaurant_uuid);

      let locale;
      for (locale in allergen.name) {
        if (allergen.name[locale])
          formData.append('name[' + locale + ']', allergen.name[locale]);
      }

      for (locale in allergen.description) {
        if (allergen.description[locale])
          formData.append(
            'description[' + locale + ']',
            allergen.description[locale]
          );
      }

      formData.append('active', allergen.active ? 1 : 0);

      if (allergen.image) {
        formData.append('image', allergen.image);
      }

      // Use post so we can send formData and we MUST send the headers
      axios
        .post(
          API_BASE +
            'restaurants/' +
            allergen.restaurant_uuid +
            '/allergens/' +
            allergen.id,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  all(context, restaurant) {
    return new Promise((resolve, reject) => {
      axios
        .get(API_BASE + 'restaurants/' + restaurant + '/allergens/all')
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  clear(context) {
    return new Promise(resolve => {
      context.commit('clear');
      resolve();
    });
  },

  generate(context, restaurant) {
    return new Promise((resolve, reject) => {
      axios
        .post(API_BASE + 'generators/allergens/' + restaurant)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  reorder(context, payload) {
    return new Promise((resolve, reject) => {
      // Just in case the reordering fails.
      const currentAllergens = context.state.allergens;

      // Set now the reordered allergens
      context.commit('set', payload.allergens);
      context.commit('error', false);
      context.commit('loading', true);

      const orderedAllergens = payload.allergens.map((allergen, index) => ({
        id: allergen.id,
        display_priority: index + 1,
      }));

      const endpoint =
        API_BASE + 'restaurants/' + payload.restaurant + '/reorder/allergens';

      axios
        .post(endpoint, { allergens: orderedAllergens })
        .then(response => {
          context.commit('set', response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          // We have and error, lets just stick to the actual ordering.
          context.commit('set', currentAllergens);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },
};

// mutations
const mutations = {
  setAllergen(state, data) {
    state.allergen = data;
  },
  set(state, data) {
    state.allergens = data;
  },
  clear(state) {
    state.allergens = [];
  },
  error(state, value) {
    state.error = value;
  },
  loading(state, value) {
    state.loading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
