import { API_BASE } from '@/constants';
import axios from 'axios';

// initial state
const state = {
  restaurants: [],
  restaurant: {},
  selected: null,
  loading: false,
  error: false,
};

// getters
const getters = {
  restaurant(state) {
    return state.restaurant;
  },
  restaurants(state) {
    return state.restaurants;
  },
  selected(state) {
    return state.selected;
  },
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
};

// actions
const actions = {
  loadRestaurant(context, restaurant) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      context.commit('error', false);

      axios
        .get(API_BASE + 'restaurants/' + restaurant)
        .then(response => {
          context.commit('setRestaurant', response.data);
          resolve(response.data);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  load(context) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      context.commit('error', false);

      axios
        .get(API_BASE + 'restaurants')
        .then(response => {
          context.commit('set', response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  select(context, selected) {
    context.commit('select', selected);
  },

  /**
   * Used for administrators so it can select any restaurant
   *
   * @param {*} context
   * @param {*} restaurant
   */
  addToList(context, restaurant) {
    context.commit('addToList', restaurant);
  },

  update(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(API_BASE + 'restaurants/' + payload.restaurant, payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getRoadTypes() {
    return new Promise((resolve, reject) => {
      axios
        .get(API_BASE + 'selects/roadtypes')
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updateConfig(context, payload) {
    context.commit('updateConfig', payload);
  },
};

// mutations
const mutations = {
  error(state, value) {
    state.error = value;
  },
  loading(state, value) {
    state.loading = value;
  },
  set(state, restaurants) {
    state.restaurants = restaurants;
  },
  setRestaurant(state, restaurant) {
    state.restaurant = restaurant;
  },
  select(state, selectedUuid) {
    state.selected = selectedUuid;
    state.restaurant = state.restaurants.find(r => r.uuid == selectedUuid);
  },

  addToList(state, restaurant) {
    if (!state.restaurants.find(r => r.uuid === restaurant.uuid)) {
      state.restaurants.push(restaurant);
      state.selected = restaurant.uuid;
    }
  },

  /**
   * Updates the configuration for all application
   *
   * @param {object} state The store state
   * @param {object} config The new configuration
   */
  updateConfig(state, config) {
    if (state.restaurant.uuid == config.restaurant_uuid) {
      state.restaurant.config = config;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
