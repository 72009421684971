import { API_BASE } from '@/constants';
import axios from 'axios';

// initial state
const state = {
  languages: [],
};

// getters
const getters = {
  languages(state) {
    return state.languages;
  },
};

// actions
const actions = {
  load() {
    return new Promise((resolve, reject) => {
      axios
        .get(API_BASE + 'selects/languages')
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  loadForRestaurant(context, restaurant) {
    return new Promise((resolve, reject) => {
      axios
        .get(API_BASE + 'restaurants/' + restaurant + '/languages')
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
