import { API_BASE } from '@/constants';
import axios from 'axios';

const queryString = payload => {
  // Create the query string
  let query = 'page=' + payload.page;

  // Check the order type
  if (payload.filter.type.length > 0) {
    query += '&type=' + payload.filter.type.join(',');
  }

  if (payload.filter.status.length > 0) {
    query += '&status=' + payload.filter.status.join(',');
  }

  if (payload.filter.table) {
    query += '&table=' + payload.filter.table;
  }

  if (payload.filter.paid !== null) {
    query += '&paid=' + payload.filter.paid;
  }

  if (payload.filter.dateFrom) {
    query += '&from=' + payload.filter.dateFrom;
  }

  if (payload.filter.dateTo) {
    query += '&to=' + payload.filter.dateTo;
  }

  return query;
};

// initial state
const state = {
  orders: [],
  last_page: 1,
  loading: false,
  error: false,
};

// getters
const getters = {
  // orders(state, getters, rootState) {
  orders(state) {
    return state.orders;
  },
  last_page(state) {
    return state.last_page;
  },
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
};

// actions
const actions = {
  filter(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('error', false);
      context.commit('loading', true);

      // Query string
      const query = queryString(payload);

      axios
        .get(
          API_BASE + 'restaurants/' + payload.restaurant + '/orders?' + query
        )
        .then(response => {
          context.commit('set', response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },
};

// mutations
const mutations = {
  set(state, { last_page, data }) {
    state.last_page = last_page;
    state.orders = data;
    state.orders.forEach(o => (o.object_data = JSON.parse(o.json_data)));
  },
  error(state, value) {
    state.error = value;
  },
  loading(state, value) {
    state.loading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
