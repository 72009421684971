export const API_BASE = process.env.VUE_APP_API_ENDPOINT;
export const AUTH_ENDPOINT = process.env.VUE_APP_AUTH_ENDPOINT;

export const MEDIA_BASE = process.env.VUE_APP_MEDIA_BASE;

// Default images (production)
export const IMAGE_DEFAULT_CATEGORY =
  process.env.VUE_APP_IMAGE_DEFAULT_CATEGORY;
export const IMAGE_DEFAULT_PRODUCT = process.env.VUE_APP_IMAGE_DEFAULT_PRODUCT;

export const IMAGE_NO_ALLERGEN = process.env.VUE_APP_IMAGE_NO_ALLERGEN;

export const IMAGE_DEFAULT_DAILY_MENU =
  process.env.VUE_APP_IMAGE_DEFAULT_DAILY_MENU;

export const PUSHER_WS_HOST = process.env.VUE_APP_PUSHER_WS_HOST;
export const PUSHER_WS_KEY = process.env.VUE_APP_PUSHER_WS_KEY;

export const EASYSERVE_VERSION = process.env.VUE_APP_VERSION;

export const STRIPE_KEY = process.env.VUE_APP_STRIPE_API_KEY;

// Flags (https://www.iconfinder.com/iconsets/flags_gosquared)
// export const FLAG_DE = 'https://s3.easyserve.es/media/lang/de.png';
// export const FLAG_EN = 'https://s3.easyserve.es/media/lang/en.png';
// export const FLAG_ES = 'https://s3.easyserve.es/media/lang/es.png';
// export const FLAG_FR = 'https://s3.easyserve.es/media/lang/fr.png';
// export const FLAG_IT = 'https://s3.easyserve.es/media/lang/it.png';
// export const FLAG_PT = 'https://s3.easyserve.es/media/lang/pt.png';

// export const FLAG_DE = 'https://localhost/api.easyserve.app/public/media/lang/de.png';
// export const FLAG_EN = 'https://localhost/api.easyserve.app/public/media/lang/en.png';
// export const FLAG_ES = 'https://localhost/api.easyserve.app/public/media/lang/es.png';
// export const FLAG_FR = 'https://localhost/api.easyserve.app/public/media/lang/fr.png';
// export const FLAG_IT = 'https://localhost/api.easyserve.app/public/media/lang/it.png';
// export const FLAG_PT = 'https://localhost/api.easyserve.app/public/media/lang/pt.png';
