import Vue from 'vue';
import Vuex from 'vuex';

import security from './modules/security';
import system from './modules/system';
import languages from './modules/languages';
import restaurants from './modules/restaurants';
import restaurantsAdministration from './modules/restaurantsAdministration';
import config from './modules/config';
import tables from './modules/tables';
import allergens from './modules/allergens';
import clients from './modules/clients';
import dailyMenus from './modules/dailyMenus';
import dailyMenuProducts from './modules/dailyMenuProducts';
import categories from './modules/categories';
import packs from './modules/packs';
import products from './modules/products';
import orders from './modules/orders';
import orderDetails from './modules/orderDetails';
import history from './modules/history';
import employees from './modules/employees';
import emailConfirmation from './modules/emailConfirmation';
import paymentMethod from './modules/paymentMethod';
import subscriptions from './modules/subscriptions';
import invoices from './modules/invoices';
import translation from './modules/translation';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    security,
    system,
    languages,
    restaurants,
    restaurantsAdministration,
    config,
    tables,
    allergens,
    clients,
    dailyMenus,
    dailyMenuProducts,
    categories,
    packs,
    products,
    orders,
    orderDetails,
    history,
    employees,
    emailConfirmation,
    paymentMethod,
    subscriptions,
    invoices,
    translation,
  },
  strict: debug,
});
