<template>
  <!-- Global router view -->
  <router-view></router-view>
</template>

<script>
import NoSleep from 'nosleep.js';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  title: 'EasyServe - Administration',

  computed: {
    ...mapGetters('restaurants', {
      restaurants: 'restaurants',
      selected: 'selected',
    }),
  },

  created() {
    this.preventDisplayTurnOff();

    // 401 errors logout the user and redirect it to the login page
    axios.interceptors.response.use(undefined, err => {
      return new Promise(() => {
        if (
          err.response &&
          err.response.status === 401 &&
          err.config &&
          !err.config.__isRetryRequest &&
          this.$route.name != 'login' &&
          // For unconfirmed users we must leave them to access the confirmation page.
          this.$route.name != 'confirm'
        ) {
          // Show the automatic logout error
          this.$store.dispatch('security/autologout');

          // Remove the listener from pusher
          this.leave(this.selected);

          // Redirect to the login page
          return this.$router.push({ name: 'login' });

          // if you ever get an unauthorized, logout the user
          // this.$store.dispatch('security/logout').then(() => {
          //   // you can also redirect to /login if needed !
          //   return this.$router.push({ name: 'login' });
          // });

          // Check for nuxt
        }

        throw err;
      });
    });

    // We need to set-up the language
  },

  methods: {
    leave(restaurant) {
      // console.log(
      //   'Leavind orders.' + restaurant + ' and clearing orders data...'
      // );

      const echo = window.Echo;
      echo.leave('orders.' + restaurant);
      echo.leave('clients.' + restaurant);
      this.$store.dispatch('orders/clear');
    },

    preventDisplayTurnOff() {
      const noSleep = new NoSleep();
      noSleep.enable();

      // console.log(noSleep.isEnabled);
    },
  },
};
</script>

<style>
@import url('./assets/css/style.css');
@import url('./assets/css/icons.css');
</style>
