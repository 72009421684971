import { API_BASE } from '@/constants';
import axios from 'axios';

// initial state
const state = {};

// getters
const getters = {};

// actions
const actions = {
  load(context, restaurant) {
    return new Promise((resolve, reject) => {
      axios
        .get(API_BASE + 'restaurants/' + restaurant + '/billing/invoices')
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  download(context, payload) {
    return new Promise((resolve, reject) => {
      const url = `${API_BASE}restaurants/${payload.restaurant}/billing/invoices/${payload.id}`;

      return axios({
        method: 'get',
        url: url,
        responseType: 'arraybuffer',
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
