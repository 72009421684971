import { API_BASE } from '@/constants';
import axios from 'axios';

const queryString = payload => {
  // Create the query string
  let query = 'page=' + payload.page;

  // Check the filters 'dni', 'displayName', 'email'
  if (payload.filter.dni != '') {
    query += '&dni=' + payload.filter.dni;
  }
  if (payload.filter.displayName != '') {
    query += '&displayName=' + payload.filter.displayName;
  }
  if (payload.filter.email != '') {
    query += '&email=' + payload.filter.email;
  }

  return query;
};

// initial state
const state = {
  employees: [],
  employee: null,
  current_page: 1,
  last_page: 1,
  loading: false,
  error: false,
};

// getters
const getters = {
  employees(state) {
    return state.employees;
  },
  employee(state) {
    return state.employee;
  },
  current_page(state) {
    return state.current_page;
  },
  last_page(state) {
    return state.last_page;
  },
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
};

// actions
const actions = {
  loadEmployee(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('error', false);
      context.commit('loading', true);

      axios
        .get(
          API_BASE +
            'restaurants/' +
            payload.restaurant +
            '/employees/' +
            payload.employee
        )
        .then(response => {
          context.commit('setEmployee', response);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  load(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('error', false);
      context.commit('loading', true);

      axios
        .get(
          API_BASE +
            'restaurants/' +
            payload.restaurant +
            '/employees?' +
            queryString(payload)
        )
        .then(response => {
          context.commit('set', response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  create(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);

      let employee = {
        first_name: payload.first_name,
        first_surname: payload.first_surname,
        second_surname: payload.second_surname,
        dni: payload.dni,
        email: payload.email,
        group_id: payload.group_id,
      };

      // TODO: Change this ...
      employee.password =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);

      axios
        .post(
          API_BASE + 'restaurants/' + payload.restaurant_uuid + '/employees',
          employee
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  update(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);

      let employee = {};

      if (Object.prototype.hasOwnProperty.call(payload, 'first_name'))
        employee.first_name = payload.first_name;

      if (Object.prototype.hasOwnProperty.call(payload, 'first_surname'))
        employee.first_surname = payload.first_surname;

      if (Object.prototype.hasOwnProperty.call(payload, 'second_surname'))
        employee.second_surname = payload.second_surname;

      if (Object.prototype.hasOwnProperty.call(payload, 'email'))
        employee.email = payload.email;

      if (Object.prototype.hasOwnProperty.call(payload, 'group_id'))
        employee.group_id = payload.group_id;

      if (Object.prototype.hasOwnProperty.call(payload, 'dni'))
        employee.dni = payload.dni;

      axios
        .post(
          API_BASE +
            'restaurants/' +
            payload.restaurant_uuid +
            '/employees/' +
            payload.id,
          employee
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  clear(context) {
    return new Promise(resolve => {
      context.commit('clear');
      resolve();
    });
  },
};

// mutations
const mutations = {
  set(state, { current_page, last_page, data }) {
    state.current_page = current_page;
    state.last_page = last_page;
    state.employees = data;
  },

  setEmployee(state, employee) {
    state.employee = employee;
  },

  clear(state) {
    state.employees = [];
  },

  error(state, value) {
    state.error = value;
  },

  loading(state, value) {
    state.loading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
