import { API_BASE } from '@/constants';
import axios from 'axios';

// initial state
const state = {};

// getters
const getters = {};

// actions
const actions = {
  load(context, restaurant) {
    return new Promise((resolve, reject) => {
      axios
        .get(API_BASE + 'restaurants/' + restaurant + '/configuration')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  update(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          API_BASE + 'restaurants/' + payload.restaurant + '/configuration',
          payload
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
