import Vue from 'vue';
import VueI18n from 'vue-i18n';

// Import languages
import en from './en';
import es from './es';

Vue.use(VueI18n);

const messages = {
  en,
  es,
};

export default new VueI18n({
  locale: 'es', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
});
