import { API_BASE } from '@/constants';
import axios from 'axios';

const queryString = payload => {
  // Create the query string
  let query = 'page=' + payload.page;

  // Check the tables
  if (payload.filter.tables.length > 0) {
    query += '&tables=' + payload.filter.tables.join(',');
  }

  if (payload.filter.status !== null) {
    query += '&' + payload.filter.status;
  }

  return query;
};

// initial state
const state = {
  clients: [],
  current_page: 1,
  last_page: 1,
  loading: false,
  error: false,
};

// getters
const getters = {
  clients(state) {
    return state.clients;
  },
  current_page(state) {
    return state.current_page;
  },
  last_page(state) {
    return state.last_page;
  },
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
};

// actions
const actions = {
  load(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('error', false);
      context.commit('loading', true);

      axios
        .get(
          API_BASE +
            'restaurants/' +
            payload.restaurant +
            '/clients?' +
            queryString(payload)
        )
        .then(response => {
          context.commit('set', response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  remove(context, client) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      axios
        .delete(
          API_BASE +
            'restaurants/' +
            client.restaurant_uuid +
            '/clients/' +
            client.id
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  authorize(context, client) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      axios
        .post(
          API_BASE +
            'restaurants/' +
            client.restaurant_uuid +
            '/clients/' +
            client.id +
            '/authorize'
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  revoke(context, client) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      axios
        .post(
          API_BASE +
            'restaurants/' +
            client.restaurant_uuid +
            '/clients/' +
            client.id +
            '/revoke'
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  update(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);

      axios
        .post(
          API_BASE +
            'restaurants/' +
            payload.restaurant +
            '/clients/' +
            payload.client,
          payload
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  inject(context, client) {
    context.commit('inject', client);
  },
};

// mutations
const mutations = {
  set(state, { current_page, last_page, data }) {
    state.current_page = current_page;
    state.last_page = last_page;
    state.clients = data;
  },

  inject(state, client) {
    // Remove last client only if we got 10
    if (state.clients.length == 10) {
      state.clients.pop();
    }

    // Inject first client
    state.clients.unshift(client);
  },

  error(state, value) {
    state.error = value;
  },
  loading(state, value) {
    state.loading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
