import { API_BASE } from '../../constants';
import axios from 'axios';

const state = {
  loading: false,
};

const getters = {
  loading(state) {
    return state.loading;
  },
};

const actions = {
  validate: (context, info) => {
    return new Promise((resolve, reject) => {
      // The Promise used for router redirect in login
      context.commit('loading', true);

      axios
        .get(API_BASE + 'confirm?token=' + info.token + '&email=' + info.email)
        .then(response => {
          // Now we have the user information data (first name, last name etc ... )
          resolve(response);
        })
        .catch(error => {
          // if the request fails, remove any possible user token if possible
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  resetPassword(context, payload) {
    return new Promise((resolve, reject) => {
      // The Promise used for router redirect in login
      context.commit('loading', true);

      axios
        .post(
          API_BASE +
            'confirm?token=' +
            payload.token +
            '&email=' +
            payload.email,
          { password: payload.password }
        )
        .then(response => {
          // Now we have the user information data (first name, last name etc ... )
          resolve(response);
        })
        .catch(error => {
          // if the request fails, remove any possible user token if possible
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },
};

const mutations = {
  loading(state, value) {
    state.loading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
