import { API_BASE } from '@/constants';
import axios from 'axios';

const queryString = payload => {
  // Create the query string
  let query = 'page=' + payload.page;

  // Check the filters 'nif', 'name', 'phone_number'
  if (payload.filter.nif != '') {
    query += '&nif=' + payload.filter.nif;
  }
  if (payload.filter.name != '') {
    query += '&name=' + payload.filter.name;
  }
  if (payload.filter.phone != '') {
    query += '&phone_number=' + payload.filter.phone;
  }

  return query;
};

// initial state
const state = {
  restaurants: [],
  last_page: 1,
  loading: false,
  error: false,
};

// getters
const getters = {
  restaurants(state) {
    return state.restaurants;
  },
  last_page(state) {
    return state.last_page;
  },
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
};

// actions
const actions = {
  load(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      context.commit('error', false);

      axios
        .get(API_BASE + 'restaurants/filter?' + queryString(payload))
        .then(response => {
          context.commit('set', response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  create(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      context.commit('error', false);

      axios
        .post(API_BASE + 'restaurants', payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },
};

// mutations
const mutations = {
  error(state, value) {
    state.error = value;
  },
  loading(state, value) {
    state.loading = value;
  },
  set(state, data) {
    state.restaurants = data.data;
    state.last_page = data.last_page;
  },
  setRestaurant(state, restaurant) {
    state.restaurant = restaurant;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
