import { API_BASE } from '@/constants';
import axios from 'axios';

// initial state
const state = {
  pending: [],
  completed: [],
  last_page: 1,
  loading: false,
  error: false,
};

// getters
const getters = {
  pending(state) {
    return state.pending;
  },
  completed(state) {
    return state.completed;
  },
  last_page(state) {
    return state.last_page;
  },
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
};

// actions
const actions = {
  loadPending(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('error', false);
      context.commit('loading', true);

      axios
        .get(
          API_BASE +
            'restaurants/' +
            payload.restaurant +
            '/orders?oldest&pending&page=' +
            payload.page
        )
        .then(response => {
          context.commit('setPending', response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  updateStatus(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('error', false);
      context.commit('loading', true);

      const requestData = {
        status: payload.status,
        paid: payload.paid,
        products: payload.servedProducts,
        daily_menus: payload.servedDailyMenus,
      };

      axios
        .patch(
          API_BASE +
            'restaurants/' +
            payload.order.restaurant_uuid +
            '/orders/' +
            payload.order.id,
          requestData
        )
        .then(response => {
          // context.commit('set', response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  updatePaid(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('error', false);
      context.commit('loading', true);

      const requestData = {
        orders: payload.orders,
      };

      axios
        .patch(
          API_BASE + 'restaurants/' + payload.restaurant + '/orders',
          requestData
        )
        .then(response => {
          // context.commit('set', response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  injectOrder(context, order) {
    context.commit('injectOrder', order);
  },

  clear(context) {
    context.commit('clear');
  },
};

// mutations
const mutations = {
  setPending(state, { last_page, data }) {
    state.last_page = last_page;
    state.pending = data;
    state.pending.forEach(o => (o.object_data = JSON.parse(o.json_data)));
  },
  injectOrder(state, order) {
    order.object_data = JSON.parse(order.json_data);
    // If there is an order already then we MUST remove that one
    // and add the newly updated one
    const index = state.pending.findIndex(o => o.id == order.id);
    if (index > -1) {
      state.pending.splice(index, 1, order);
    } else {
      state.pending.push(order);
    }
  },
  clear(state) {
    state.last_page = 1;
    state.pending = [];
  },
  error(state, value) {
    state.error = value;
  },
  loading(state, value) {
    state.loading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
