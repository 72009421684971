export default {
  login: {
    title: 'Login form',
    email: 'Email',
    password: 'Password',

    reset: 'Forgot my password',
    login: 'Login',

    // Validations
    v: {
      email: {
        email: 'The email is not valid.',
        required: 'Email is required.',
      },

      password: {
        required: 'The password is required.',
      },
    },
  },

  /**
   * Navigation
   */
  navigation: {
    clients: 'Authorizations',
    orders: 'Orders',
    history: 'History (orders)',
    categories: 'Categories / Products',
    dailyMenus: 'Daily menus',
    allergens: 'Allergens',
    receipts: 'Receipts',
    employees: 'Employees',
    configuration: 'Configuration',
    contactInformation: 'Contact Information',
    tables: 'Tables',
    feedback: 'Feedback',
    paymentMethod: 'Payment Methods',
    subscriptions: 'Subscriptions',
    invoices: 'Invoices',
  },

  /**
   * Orders
   */
  orders: {
    title: 'Orders', // Menús del día
    noOrders: 'There are no orders currently in the system.',
  },

  /**
   * History
   */
  history: {
    title: 'History (orders)', // Menús del día
    noOrders: 'There are no orders currently in the system.',

    typeList: {
      order: 'Order',
      bill: 'Bill',
      waiter: 'Waiter',
    },
  },

  /**
   * Daily menus
   */
  dailyMenus: {
    title: 'Daily menus', // Menús del día
    noDailyMenus:
      'There are no daily menus currently in the system. Please use the "add" button found bottom right to create one.',

    breadcrumb: {
      edit: '{name}',
      create: 'New daily menu',
    },

    dailyMenu: {
      edit: 'Edit',
      delete: 'Delete',
      noDescription: "This daily menu doesn't have a description.",
    },
  },

  /**
   * Categories
   */
  categories: {
    title: 'Categories',

    breadcrumb: {
      edit: '{name}',
      create: 'New category',
    },

    noCategories:
      'There are no category currently in the system. Please use the "add" button found bottom right to create one.',

    category: {
      edit: 'Edit',
      delete: 'Delete',
      noDescription: "This category doesn't have a description.",
    },
  },

  /**
   * Products
   */
  products: {
    breadcrumb: {
      create: 'Create',
      edit: '{name}',
    },

    title: 'Products',

    noProducts:
      'There are no products currently in the system. Please use the "add" button found bottom right to create one.',

    product: {
      edit: 'Edit',
      delete: 'Delete',
      noDescription: "This product doesn't have a description.",
    },
  },

  /**
   * Allergens
   */
  allergens: {
    breadcrumb: {
      create: 'Create',
      edit: '{name}',
    },

    title: 'Allergens',

    noAllergens:
      'There are no allergens currently in the system. Please use the "add" button found bottom right to create one.',

    allergen: {
      edit: 'Edit',
      delete: 'Delete',
      noDescription: "This allergen doesn't have a description.",
    },
  },

  /**
   * Clients
   */
  clients: {
    title: 'Clients',

    noClients:
      'There are no clients currently in the system for the search criteria.',
  },

  /**
   * Client
   */
  client: {
    notAuthorized: 'Not authorized', // No autorizado
  },

  /**
   * Configuration
   */
  configuration: {
    title: 'Configuration',
  },

  /**
   * Employees
   */
  employees: {
    title: 'Employee',
    breadcrumb: {
      create: 'New employee',
      edit: '{name}',
    },
  },

  /**
   * Payment Methods
   */
  paymentMethods: {
    title: 'Payment Methods',
  },

  /**
   * Subscriptions
   */
  subscriptions: {
    title: 'Plans & Subscriptions',
  },

  validations: {
    required: 'The field is required.',
    required_i18n: 'The field is mandatory in all languages.',
    numeric: 'The field must be numeric.',
    length: 'The field can contain a maximum of {0} characters.',
    fileSize: 'The image size cannot exceed {0}kb.',
    email: 'Invalid e-mail address.',
    dni: 'The identity document format is invalid.',
    equalsTo: 'The passwords does not match.',
  },
};
