import Vue from 'vue';
import Dinero from 'dinero.js';

Vue.filter('dinero', function (price) {
  if (isNaN(price)) return '';
  if (!price) return '';
  return Dinero({
    amount: parseInt(price),
    currency: 'EUR',
  })
    .setLocale('es-ES')
    .toFormat('0,0.00');
});

Vue.filter('currency_money', function (price) {
  if (isNaN(price)) return '';
  if (!price) return '';
  return Dinero({
    amount: parseInt(price),
    currency: 'EUR',
  })
    .setLocale('es-ES')
    .toFormat('$0,0.00');
});

Vue.filter('money', function (price) {
  if (isNaN(price)) return '';
  if (!price) return '';

  return (price / 100).toLocaleString('es-ES', {
    style: 'currency',
    currency: 'EUR',
  });
});

Vue.filter('translate', function (obj, i18n) {
  if (!obj) return '';

  return obj[i18n.locale] ? obj[i18n.locale] : obj[i18n.fallbackLocale];
});
