import { API_BASE } from '@/constants';
import axios from 'axios';

export const BEVERAGES = 'BEVERAGES';
export const FIRST_DISHES = 'FIRST_DISHES';
export const SECOND_DISHES = 'SECOND_DISHES';
export const DESSERTS = 'DESSERTS';
export const COFFEES = 'COFFEES';

// initial state
const state = {
  dailyMenu: {},
  config: {},
  products: [],
  product: {},

  step: 2,

  group: BEVERAGES,

  loading: false,
  error: false,
};

// getters
const getters = {
  dailyMenu(state) {
    return state.dailyMenu;
  },
  config(state) {
    return state.config;
  },
  products(state) {
    return state.products;
  },
  product(state) {
    return state.product;
  },

  step(state) {
    return state.step;
  },
  group(state) {
    return state.group;
  },

  beverages(state) {
    return state.products.filter(p => p.group === BEVERAGES);
  },
  first_dishes(state) {
    return state.products.filter(p => p.group === FIRST_DISHES);
  },
  second_dishes(state) {
    return state.products.filter(p => p.group === SECOND_DISHES);
  },
  desserts(state) {
    return state.products.filter(p => p.group === DESSERTS);
  },
  coffees(state) {
    return state.products.filter(p => p.group === COFFEES);
  },

  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
};

// actions
const actions = {
  // get('/restaurants/{restaurant}/daily-menu-products/{product}
  loadProduct(context, payload) {
    // Return a promise and use the page to load the data
    return new Promise((resolve, reject) => {
      // Clean any errors
      context.commit('error', false);
      context.commit('loading', true);

      axios
        .get(
          API_BASE +
            'restaurants/' +
            payload.restaurant +
            '/daily-menu-products/' +
            payload.product
        )
        .then(response => {
          context.commit('setProduct', response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  load(context, payload) {
    // Return a promise and use the page to load the data
    return new Promise((resolve, reject) => {
      // Clean any errors
      context.commit('error', false);
      context.commit('loading', true);

      axios
        .get(
          API_BASE +
            'restaurants/' +
            payload.restaurant +
            '/daily-menus/' +
            payload.dailyMenu +
            '/products'
        )
        .then(response => {
          context.commit('set', response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  create(context, product) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      axios
        .post(
          API_BASE +
            'restaurants/' +
            product.restaurant_uuid +
            '/daily-menus/' +
            product.daily_menu_id +
            '/products',
          product
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  update(context, product) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      axios
        .post(
          API_BASE +
            'restaurants/' +
            product.restaurant_uuid +
            '/daily-menu-products/' +
            product.id,
          product
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  remove(context, product) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      axios
        .delete(
          API_BASE +
            'restaurants/' +
            product.restaurant_uuid +
            '/daily-menu-products/' +
            product.id
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  updateConfig(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      axios
        .patch(
          API_BASE +
            'restaurants/' +
            payload.restaurant +
            '/daily-menus/' +
            payload.dailyMenu +
            '/config',
          payload.dailyMenuConfig
        )
        .then(response => {
          context.commit('updateConfig', response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  setStep(context, step) {
    context.commit('setStep', step);
  },
  setGroup(context, group) {
    context.commit('setGroup', group);
  },
};

// mutations
const mutations = {
  set(state, data) {
    state.dailyMenu = data;
    state.config = data.config;
    state.products = data.products;
  },
  setProduct(state, data) {
    state.dailyMenu = data.daily_menu;
    state.product = data;
  },
  updateConfig(state, data) {
    state.dailyMenu.config = data;
    state.config = data;
  },

  setStep(state, step) {
    state.step = step;
  },
  setGroup(state, group) {
    state.group = group;
  },

  error(state, value) {
    state.error = value;
  },
  loading(state, value) {
    state.loading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
