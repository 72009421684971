import { API_BASE } from '@/constants';
import axios from 'axios';

// initial state
const state = {
  category: {},
  products: [],
  product: {},
  loading: false,
  error: false,
};

// getters
const getters = {
  category(state) {
    return state.category;
  },
  products(state) {
    return state.products;
  },
  product(state) {
    return state.product;
  },
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
};

// actions
const actions = {
  load(context, payload) {
    // Return a promise and use the page to load the data
    return new Promise((resolve, reject) => {
      // Clean any errors
      context.commit('error', false);
      context.commit('loading', true);

      const endpoint = `${API_BASE}restaurants/${payload.restaurant}/categories/${payload.category}/products`;
      axios
        .get(endpoint)
        .then(response => {
          context.commit('set', response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  loadProduct(context, payload) {
    // Return a promise and use the page to load the data
    return new Promise((resolve, reject) => {
      // Clean any errors
      context.commit('error', false);
      context.commit('loading', true);

      axios
        .get(
          `${API_BASE}restaurants/${payload.restaurant}/products/${payload.product}`
        )
        .then(response => {
          context.commit('setProduct', response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  create(context, product) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);

      // For this to work we need a form data object
      const formData = new FormData();
      formData.append('restaurant_uuid', product.restaurant_uuid);
      formData.append('category_id', product.category_id);

      let locale;
      for (locale in product.name) {
        if (product.name[locale])
          formData.append('name[' + locale + ']', product.name[locale]);
      }

      for (locale in product.description) {
        if (product.description[locale])
          formData.append(
            'description[' + locale + ']',
            product.description[locale]
          );
      }

      formData.append('price', product.price);
      formData.append('active', product.active ? 1 : 0);
      formData.append('display_priority', product.display_priority);

      // We need to do it this way, an array in form data is converted to a string
      for (var i = 0; i < product.allergens.length; i++) {
        formData.append('allergens[]', product.allergens[i]);
      }

      if (product.image) {
        formData.append('image', product.image);
      }

      axios
        .post(
          API_BASE +
            'restaurants/' +
            product.restaurant_uuid +
            '/categories/' +
            product.category_id +
            '/products',
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  update(context, product) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);

      // For this to work we need a form data object
      const formData = new FormData();
      formData.append('restaurant_uuid', product.restaurant_uuid);
      formData.append('category_id', product.category_id);

      let locale;
      for (locale in product.name) {
        if (product.name[locale] !== null)
          formData.append('name[' + locale + ']', product.name[locale]);
      }

      for (locale in product.description) {
        if (product.description[locale] !== null)
          formData.append(
            'description[' + locale + ']',
            product.description[locale]
          );
      }

      formData.append('price', product.price);
      formData.append('active', product.active ? 1 : 0);
      formData.append('display_priority', product.display_priority);

      // We need to do it this way, an array in form data is converted to a string
      for (var i = 0; i < product.allergens.length; i++) {
        formData.append('allergens[]', product.allergens[i]);
      }

      if (product.image) {
        formData.append('image', product.image);
      }

      axios
        .post(
          API_BASE +
            'restaurants/' +
            product.restaurant_uuid +
            '/products/' +
            product.id,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  remove(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      axios
        .delete(
          API_BASE +
            'restaurants/' +
            payload.restaurant +
            '/products/' +
            payload.product
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', error);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  clear(context) {
    return new Promise(resolve => {
      context.commit('clear');
      resolve();
    });
  },

  reorder(context, payload) {
    return new Promise((resolve, reject) => {
      // Just in case the reordering fails.
      const currentProducts = context.state.products;

      // Set now the reordered products
      context.commit('setProducts', payload.products);
      context.commit('error', false);
      context.commit('loading', true);

      const orderedProducts = payload.products.map((product, index) => ({
        id: product.id,
        display_priority: index + 1,
      }));

      const endpoint = `${API_BASE}restaurants/${payload.restaurant}/reorder/${payload.category}/products`;
      axios
        .post(endpoint, { products: orderedProducts })
        .then(response => {
          context.commit('setProducts', response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          // We have and error, lets just stick to the actual ordering.
          context.commit('set', currentProducts);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },
};

// mutations
const mutations = {
  error(state, value) {
    state.error = value;
  },
  loading(state, value) {
    state.loading = value;
  },
  set(state, category) {
    state.category = category;
    state.products = category.products;
  },
  setProducts(state, products) {
    state.products = products;
  },
  setProduct(state, product) {
    state.product = product;
    state.category = product.category;
  },
  clear(state) {
    state.products = [];
    state.category = {};
    state.product = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
