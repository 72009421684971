import { API_BASE } from '@/constants';
import axios from 'axios';

// initial state
const state = {
  order: {},
  items: [],
  loading: false,
  error: false,
};

// getters
const getters = {
  order(state) {
    return state.order;
  },
  items(state) {
    return state.items;
  },
  products(state) {
    return state.items.filter(i => i.buyable_type === 'App\\Models\\Product');
  },
  dailyMenus(state) {
    return state.items.filter(i => i.buyable_type === 'App\\Models\\DailyMenu');
  },
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
};

// actions
const actions = {
  load(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('error', false);
      context.commit('loading', true);

      axios
        .get(
          API_BASE +
            'restaurants/' +
            payload.restaurant +
            '/orders/' +
            payload.order
        )
        .then(response => {
          context.commit('set', response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },
};

// mutations
const mutations = {
  set(state, data) {
    state.order = data;
    state.items = data.items;
    state.items.forEach(i => {
      // Revert the json
      i.object_data = JSON.parse(i.json_data);
    });
  },
  error(state, value) {
    state.error = value;
  },
  loading(state, value) {
    state.loading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
