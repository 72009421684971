import { AUTH_ENDPOINT, PUSHER_WS_HOST, PUSHER_WS_KEY } from './constants';

// Axios
import axios from 'axios';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Authentication
 */
const token_type = localStorage.getItem('token_type');
const access_token = localStorage.getItem('access_token');
if (access_token) {
  axios.defaults.headers.common['Authorization'] = token_type + access_token;
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
  authEndpoint: AUTH_ENDPOINT,
  wsHost: PUSHER_WS_HOST,
  wsPort: 443,
  wssPort: 443,
  enabledTransports: ['ws', 'wss'],
  broadcaster: 'pusher',
  key: PUSHER_WS_KEY,
  cluster: 'eu',
  encrypted: false,
  forceTLS: true,
  disableStats: true,
  authorizer: (channel) => {
    return {
        authorize: (socketId, callback) => {
            axios.post(AUTH_ENDPOINT, {
                socket_id: socketId,
                channel_name: channel.name
            })
            .then(response => {
                callback(null, response.data);
            })
            .catch(error => {
                callback(error);
            });
        }
    };
  },
});
