import { API_BASE } from '@/constants';
import axios from 'axios';

const state = {
  activeSubscription: '',
  paymentMethods: [],
  defaultPaymentMethod: {},

  error: false,
  loading: false,
};

const getters = {
  activeSubscription(state) {
    return state.activeSubscription;
  },
  paymentMethods(state) {
    return state.paymentMethods;
  },
  defaultPaymentMethod(state) {
    return state.defaultPaymentMethod;
  },
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
};

const actions = {
  loadPaymentMethods(context, restaurant) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      context.commit('error', false);

      axios
        .get(
          API_BASE + 'restaurants/' + restaurant + '/billing/payment-methods'
        )
        .then(response => {
          context.commit('setPaymentMethods', response.data);
          resolve(response.data);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  createSetupIntent(context, restaurant) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      context.commit('error', false);

      const uri = `${API_BASE}restaurants/${restaurant}/billing/payment-methods/setup-intent`;
      axios
        .get(uri)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  store(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      context.commit('error', false);

      const attributes = {
        payment_method: payload.payment_method,
        default_method: payload.default_method,
      };

      const endpoint = `${API_BASE}restaurants/${payload.restaurant}/billing/payment-methods`;
      axios
        .post(endpoint, attributes)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  destroy(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      context.commit('error', false);

      const attributes = {
        payment_method: payload.payment_method,
      };

      const endpoint = `${API_BASE}restaurants/${payload.restaurant}/billing/payment-methods`;
      axios
        .delete(endpoint, { data: attributes })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  updateDefault(context, payload) {
    // restaurants/{restaurant}/billing/default-payment-method
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      context.commit('error', false);

      const attributes = {
        payment_method: payload.payment_method,
      };

      const endpoint = `${API_BASE}restaurants/${payload.restaurant}/billing/default-payment-method`;
      axios
        .post(endpoint, attributes)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },
};

const mutations = {
  error(state, value) {
    state.error = value;
  },
  loading(state, value) {
    state.loading = value;
  },

  setPaymentMethods(state, data) {
    state.paymentMethods = data.paymentMethods;
    state.defaultPaymentMethod = data.defaultPaymentMethod;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
