import { API_BASE } from '../../constants';
import axios from 'axios';

const state = {
  access_token: localStorage.getItem('access_token') || null,
  token_type: localStorage.getItem('token_type') || null,
  expires_at: localStorage.getItem('expires_at') || null,
  user: JSON.parse(localStorage.getItem('user')) || null,
  autologout: false,
  loading: false,
  error: false,
};

const getters = {
  // loading(state, getters, rootState) {
  //     return state.loading;
  // },
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
  isAuthenticated(state) {
    if (state.expires_at === null || state.expires_at < new Date()) {
      return false;
    }

    if (state.access_token === null) {
      return false;
    }

    return true;
  },
  user(state) {
    return state.user;
  },
  isAdmin(state) {
    return state.user && state.user.group_id == 1;
  },
  isEmployee(state) {
    return state.user && state.user.group_id == 2;
  },
  isOwner(state) {
    return state.user && state.user.group_id == 3;
  },
  isWaiter(state) {
    return state.user && state.user.group_id == 4;
  },
  autologout(state) {
    return state.autologout;
  },
};

const actions = {
  login: (context, credentials) => {
    return new Promise((resolve, reject) => {
      // The Promise used for router redirect in login
      context.commit('loading', true);
      context.commit('error', false);

      axios
        .post(API_BASE + 'login', credentials)
        .then(response => {
          const access_token = response.data.access_token;
          const token_type = response.data.token_type;
          const expires_at = response.data.expires_at;
          const user = response.data.user;

          localStorage.setItem('access_token', access_token); // store the token in localstorage
          localStorage.setItem('token_type', token_type); // store the token type in localstorage
          localStorage.setItem('expires_at', expires_at); // store the token expiration date / time in localstorage
          localStorage.setItem('user', JSON.stringify(user)); // Store the user data (group)

          context.commit('login', response.data);
          // you have your token, now log in your user :)
          // dispatch(USER_REQUEST)
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);

          // if the request fails, remove any possible user token if possible
          localStorage.removeItem('access_token');
          localStorage.removeItem('token_type');
          localStorage.removeItem('expires_at');
          localStorage.removeItem('user');

          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  logout: context => {
    return new Promise((resolve, reject) => {
      context.commit('logout');

      axios.post(API_BASE + 'logout').catch(error => reject(error));

      // Clear your user's token from localstorage
      localStorage.removeItem('access_token');
      localStorage.removeItem('token_type');
      localStorage.removeItem('expires_at');
      localStorage.removeItem('user');

      // Remove credentials
      axios.defaults.headers.common['Authorization'] = '';

      // Set the echo authorization token
      if (window.Echo.connector.pusher.config.auth) { 
        window.Echo.connector.pusher.config.auth.headers['Authorization'] = '';
      }

      resolve();
    });
  },

  autologout: context => {
    // Clear your user's token from localstorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('token_type');
    localStorage.removeItem('expires_at');

    // Clear everything
    context.commit('logout');
    context.commit('autologout');
  },

  sendResetPasswordEmail: (context, email) => {
    return new Promise((resolve, reject) => {
      // The Promise used for router redirect in login
      context.commit('loading', true);
      context.commit('error', false);

      axios
        .post(API_BASE + 'forgot-password', email)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  resetPassword(context, payload) {
    return new Promise((resolve, reject) => {
      // The Promise used for router redirect in login
      context.commit('loading', true);
      context.commit('error', false);

      axios
        .post(API_BASE + 'reset-password', payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          // if the request fails, set the error flag and reject
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },
};

const mutations = {
  loading(state, value) {
    state.loading = value;
  },
  error: (state, value) => {
    state.error = value;
  },
  login: (state, data) => {
    state.error = false;
    state.access_token = data.access_token;
    state.token_type = data.token_type;
    state.expires_at = data.expires_at;
    state.user = data.user;
  },
  logout: state => {
    state.error = false;
    state.access_token = null;
    state.token_type = null;
    state.expires_at = null;
    state.user = null;
  },
  autologout: state => {
    state.autologout = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
