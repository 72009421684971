// Require laravel echo
require('./bootstrap');

// Require filters
require('./filters/filters');

// Require dayjs js translation
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/pt';
import 'dayjs/locale/it';
import 'dayjs/locale/de';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';

import titleMixin from './mixins/title';

// Event bus
window.events = new Vue();

Vue.config.productionTip = false;
Vue.mixin(titleMixin);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');
