const state = {
  connected: true,
};

const getters = {
  isConnected(state) {
    return state.connected;
  },
};

const actions = {
  connect: context => {
    context.commit('connect');
  },
  disconnect: context => {
    context.commit('disconnect');
  },
};

const mutations = {
  connect: state => {
    state.connected = true;
  },
  disconnect: state => {
    state.connected = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
