import store from '../store'; // your vuex store

import Vue from 'vue';
import VueRouter from 'vue-router';
import goTo from 'vuetify/lib/services/goto';

const ifAdminOrEmployee = (to, from, next) => {
  if (
    store.getters['security/isAdmin'] ||
    store.getters['security/isEmployee']
  ) {
    next();
    return;
  }

  // Go to the default restaurant orders page
  next('/login');
};

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters['security/isAuthenticated']) {
    next();
    return;
  }

  // Go to the default restaurant orders page
  next('/');
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters['security/isAuthenticated']) {
    if (to.path == '/') {
      // Load all restaurants
      store
        .dispatch('restaurants/load')
        .then(response => {
          // If there are no restaurants
          if (!response.data[0].uuid) {
            return next('/404');
          }

          // Select the restaurant here
          store.dispatch('restaurants/select', response.data[0].uuid);

          // Redirect to the orders
          next({
            name: 'orders',
            params: { restaurant: response.data[0].uuid },
          });
        })
        .catch(error => {
          if (error.response.status == 401) {
            next({ name: 'login' });
          }
        });

      return;
    }

    next();
    return;
  }

  next('/login');
};

Vue.use(VueRouter);

const routes = [
  {
    // Only for redirection purpose
    path: '/',
    name: 'home',
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/404',
    name: 'notfound',
    component: () =>
      import(
        /* webpackChunkName: "security" */ '../views/errors/EximyrError404.vue'
      ),
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(
        /* webpackChunkName: "security" */ '../views/security/EasyServeLogin.vue'
      ),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/reset',
    name: 'reset',
    component: () =>
      import(
        /* webpackChunkName: "security" */ '../views/security/EasyServeSendPasswordResetLink.vue'
      ),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: () =>
      import(
        /* webpackChunkName: "security" */ '../views/security/EasyServePasswordReset.vue'
      ),
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: '/confirm',
    name: 'confirm',
    component: () =>
      import(/* webpackChunkName: "confirm" */ '../views/EasyServeConfirm.vue'),
  },

  {
    path: '/administration',
    // route level code-splitting
    // this generates a separate chunk (skeleton.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "skeleton" */ '../views/EximyrSkeleton.vue'),
    beforeEnter: ifAdminOrEmployee,
    children: [
      {
        path: 'restaurants',
        name: 'administration.restaurants',
        component: () =>
          import(
            /* webpackChunkName: "administration" */ '../views/administration/EasyServeRestaurants.vue'
          ),
      },
      {
        path: 'packs',
        name: 'administration.packs',
        component: () =>
          import(
            /* webpackChunkName: "administration" */ '../views/administration/EasyServePacks.vue'
          ),
      },
      {
        path: 'restaurants/create',
        name: 'administration.restaurants.create',
        component: () =>
          import(
            /* webpackChunkName: "administration" */ '../views/administration/EasyServeRestaurantForm.vue'
          ),
      },
    ],
  },

  {
    path: '/:restaurant',
    // route level code-splitting
    // this generates a separate chunk (skeleton.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "skeleton" */ '../views/EximyrSkeleton.vue'),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: 'clients',
        name: 'clients',
        component: () =>
          import(
            /* webpackChunkName: "clients" */ '../views/EximyrClients.vue'
          ),
      },
      {
        path: 'orders',
        name: 'orders',
        component: () =>
          import(/* webpackChunkName: "orders" */ '../views/EximyrOrders.vue'),
      },
      {
        path: 'history',
        name: 'history',
        component: () =>
          import(
            /* webpackChunkName: "history" */ '../views/EximyrHistory.vue'
          ),
      },

      {
        path: 'categories',
        name: 'categories',
        component: () =>
          import(
            /* webpackChunkName: "categories" */ '../views/EximyrCategories.vue'
          ),
      },
      {
        path: 'categories/:category/edit',
        name: 'categories.edit',
        component: () =>
          import(
            /* webpackChunkName: "categories" */ '../views/EximyrCategoryForm.vue'
          ),
      },
      {
        path: 'categories/create',
        name: 'categories.create',
        component: () =>
          import(
            /* webpackChunkName: "categories" */ '../views/EximyrCategoryForm.vue'
          ),
      },

      {
        path: 'categories/:category/products',
        name: 'products',
        component: () =>
          import(
            /* webpackChunkName: "products" */ '../views/EximyrProducts.vue'
          ),
      },
      {
        path: 'categories/:category/products/create',
        name: 'products.create',
        component: () =>
          import(
            /* webpackChunkName: "products" */ '../views/EximyrProductForm.vue'
          ),
      },
      {
        path: 'categories/:category/products/:product/edit',
        name: 'products.edit',
        component: () =>
          import(
            /* webpackChunkName: "products" */ '../views/EximyrProductForm.vue'
          ),
      },

      {
        path: 'daily-menus',
        name: 'daily-menus',
        component: () =>
          import(
            /* webpackChunkName: "daily-menus" */ '../views/EximyrDailyMenus.vue'
          ),
      },
      {
        path: 'daily-menus/:dailyMenu/edit',
        name: 'daily-menus.edit',
        component: () =>
          import(
            /* webpackChunkName: "daily-menus" */ '../views/EximyrDailyMenuForm.vue'
          ),
      },
      {
        path: 'daily-menus/create',
        name: 'daily-menus.create',
        component: () =>
          import(
            /* webpackChunkName: "daily-menus" */ '../views/EximyrDailyMenuForm.vue'
          ),
      },
      {
        path: 'daily-menus/:dailyMenu/products',
        name: 'daily-menus.products',
        component: () =>
          import(
            /* webpackChunkName: "daily-menus" */ '../views/EximyrDailyMenuProducts.vue'
          ),
      },
      {
        path: 'daily-menus/:dailyMenu/products/create',
        name: 'daily-menus.products.create',
        component: () =>
          import(
            /* webpackChunkName: "daily-menus" */ '../views/EximyrDailyMenuProductForm.vue'
          ),
      },
      {
        path: 'daily-menus/:dailyMenu/:product/edit',
        name: 'daily-menus.products.edit',
        component: () =>
          import(
            /* webpackChunkName: "daily-menus" */ '../views/EximyrDailyMenuProductForm.vue'
          ),
      },

      {
        path: 'allergens',
        name: 'allergens',
        component: () =>
          import(
            /* webpackChunkName: "allergens" */ '../views/EximyrAllergens.vue'
          ),
      },

      {
        path: 'allergens/:allergen/edit',
        name: 'allergens.edit',
        component: () =>
          import(
            /* webpackChunkName: "allergens" */ '../views/EximyrAllergenForm.vue'
          ),
      },

      {
        path: 'allergens/create',
        name: 'allergens.create',
        component: () =>
          import(
            /* webpackChunkName: "allergens" */ '../views/EximyrAllergenForm.vue'
          ),
      },

      {
        path: 'configuration',
        name: 'configuration',
        component: () =>
          import(
            /* webpackChunkName: "configuration" */ '../views/EximyrConfiguration.vue'
          ),
      },
      {
        path: 'contact-information',
        name: 'contact-information',
        component: () =>
          import(
            /* webpackChunkName: "contact-information" */ '../views/EximyrContactInformation.vue'
          ),
      },
      {
        path: 'tables',
        name: 'tables',
        component: () =>
          import(/* webpackChunkName: "tables" */ '../views/EximyrTables.vue'),
      },

      {
        path: 'employees',
        name: 'employees',
        component: () =>
          import(
            /* webpackChunkName: "employees" */ '../views/EximyrEmployees.vue'
          ),
      },
      {
        path: 'employees/create',
        name: 'employees.create',
        component: () =>
          import(
            /* webpackChunkName: "employees" */ '../views/EximyrEmployeeForm.vue'
          ),
      },
      {
        path: 'employees/:employee/edit',
        name: 'employees.edit',
        component: () =>
          import(
            /* webpackChunkName: "employees" */ '../views/EximyrEmployeeForm.vue'
          ),
      },

      {
        path: 'payment-method',
        name: 'payment-method',
        component: () =>
          import(
            /* webpackChunkName: "payment-method" */ '../views/EasyServePaymentMethod.vue'
          ),
      },

      {
        path: 'subscriptions',
        name: 'subscriptions',
        component: () =>
          import(
            /* webpackChunkName: "subscriptions" */ '../views/EasyServeSubscriptions.vue'
          ),
      },

      {
        path: 'invoices',
        name: 'invoices',
        component: () =>
          import(
            /* webpackChunkName: "invoices" */ '../views/EasyServeInvoices.vue'
          ),
      },

      {
        path: 'feedback',
        name: 'feedback',
        component: () =>
          import(
            /* webpackChunkName: "feedback" */ '../views/EximyrFeedback.vue'
          ),
      },
    ],
  },
  {
    path: '*',
    redirect: '/404',
  },
];

const router = new VueRouter({
  mode: 'history',

  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },

  base: process.env.BASE_URL,
  routes,
});

export default router;
