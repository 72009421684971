import { API_BASE } from '@/constants';
import axios from 'axios';

const queryString = payload => {
  // Create the query string
  let query = 'page=' + payload.page;

  // Check the filters
  if (payload.filter.alias) {
    query += '&alias=' + payload.filter.alias;
  }
  if (payload.filter.created_at) {
    query += '&created_at=' + payload.filter.created_at;
  }
  if (payload.filter.from_tables) {
    query += '&from_tables=' + payload.filter.from_tables;
  }
  if (payload.filter.to_tables) {
    query += '&to_tables=' + payload.filter.to_tables;
  }
  if (payload.filter.printed != null) {
    query += '&printed=' + payload.filter.printed;
  }
  if (payload.filter.assigned != null) {
    query += '&assigned=' + payload.filter.assigned;
  }

  return query;
};

// initial state
const state = {
  packs: [],
  last_page: 1,
  loading: false,
  error: false,
};

// getters
const getters = {
  packs(state) {
    return state.packs;
  },
  last_page(state) {
    return state.last_page;
  },
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
};

// actions
const actions = {
  load(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      context.commit('error', false);

      axios
        .get(API_BASE + 'packs?' + queryString(payload))
        .then(response => {
          context.commit('set', response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  associate(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      context.commit('error', false);

      axios
        .post(API_BASE + 'packs/associate', payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },
};

// mutations
const mutations = {
  error(state, value) {
    state.error = value;
  },
  loading(state, value) {
    state.loading = value;
  },
  set(state, data) {
    state.packs = data.data;
    state.last_page = data.last_page;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
